define("discourse/plugins/swapd-sponsoring/discourse/initializers/search-sponsoring", ["exports", "discourse/lib/plugin-api", "discourse-common/utils/decorators"], function (_exports, _pluginApi, _decorators) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = {
    name: "swapd-sponsoring",
    initialize(container) {
      (0, _pluginApi.withPluginApi)("1.3.0", api => {
        var _dec, _obj;
        // dropdown top right
        api.reopenWidget(`search-result-topic`, {
          html(attrs) {
            const html = this._super(attrs);
            for (let i = 0; i < attrs.results.length; i++) {
              if (attrs.results[i].blurb.startsWith('<span class="sponcont">')) {
                html[i].properties.className = 'item spon-result';
              }
            }
            return html;
          }
        });

        // full page search
        api.modifyClass("component:search-result-entry", (_dec = (0, _decorators.default)("model.post.blurb"), (_obj = {
          pluginId: "swapd-sponsoring",
          classNameBindings: ['isSponsored:spon-result'],
          isSponsored() {
            return this?.post?.blurb?.startsWith('<span class="sponcont">');
          }
        }, (_applyDecoratedDescriptor(_obj, "isSponsored", [_dec], Object.getOwnPropertyDescriptor(_obj, "isSponsored"), _obj)), _obj)));
      });
    }
  };
});